import { local } from "d3";
import User from "../models/User";

class WVZ {

    public static SESSION_TOKEN: string = 'accessToken';

    public static STAY_CONNECTED: string = 'stayConnected';

    public static USER: string = 'user';

    // public static API_URL: string | undefined = process.env.REACT_APP_SERVER_URL; 
    public static API_URL: string | undefined = 'http://portal.wevez.it/api/v1/'

    public static DEFAULT_DATE : Date = new Date('1999-12-31T23:59:59'); 

    public static authHeader = () => {
        let accessToken: any | null;

        // Verifico che non sia presente il token salvato da Tienimi Connesso
        if(localStorage.getItem(WVZ.STAY_CONNECTED)){
          accessToken = localStorage.getItem(WVZ.SESSION_TOKEN);
        }else{
          accessToken = sessionStorage.getItem(WVZ.SESSION_TOKEN);
        }
      
        if (accessToken) {
          return  'Bearer ' + accessToken ;
        } else {
          return '';
        }
      }

    public static loggedUser = () => {
      let jsonUser;

      // Verifico che non sia presente il token salvato da Tienimi Connesso
      if(localStorage.getItem(WVZ.STAY_CONNECTED)){
        jsonUser = localStorage.getItem(WVZ.USER);
      }else{
        jsonUser = sessionStorage.getItem(WVZ.USER);
      }
      
      if (jsonUser) {
        return JSON.parse(jsonUser);
      } else {
        return '';
      }
    }

    public static mustUpdate :boolean = false;
  
}

export default WVZ;