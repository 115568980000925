import { useEffect, useState } from "react";

import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";

import "../styles/monitoraggio.css";

import { CategoryScale, Chart as ChartJS } from "chart.js/auto";

import DeviceService from "../services/devices.service";

import Device from "../models/Device";
import WVZ from "../context/WVZ";

import GraphPowerInstant from "./Charts/GraphPowerInstant";
import GraphLastHour from "./Charts/GraphLastHour";
import GraphYesterday from "./Charts/GraphYesterday";
import IstogrammaWeek from "./Charts/IstogrammaWeek";

export default function Monitoraggio() {
  ChartJS.register(CategoryScale); //setto i grafici qui altrimenti devo farlo in tutti i componenti

  const [showing, setShowing] = useState(false);

  const currentUser = WVZ.loggedUser();

  const deviceService = new DeviceService();

  //const [userDevices, setUserDevices] = useState<Device[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>();

  //solo per non visualizzare i menu in base ai px
  const setMenu = () => {
    let box: any | undefined | null;
    box = document.querySelector("body");

    let width = box.offsetWidth;

    if (width > 1000) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  };

  setTimeout(function () {
    setMenu();
  }, 1);

  window.onresize = () => {
    setMenu();
  };
  
  /* Ricavo i devices legati all'utente che ha effettuato l'accesso
 window.onload = () => {
    console.log("ciao");
    deviceService.getUserDevices(currentUser.id).then((result) =>{
      if(result != null) setDevices(result); //salvo i devices
    })
    .catch((error) => {
      console.log(error);
    })
  };*/

  //Ricavo i devices legati all'utente che ha effettuato l'accesso (al posto di wondow.onload)
  useEffect(()=>{
    deviceService.getUserDevices(currentUser.id).then((result) =>{
      if(result != null) setDevices(result); //salvo i devices
    })
    .catch((error) => {
      console.log(error);
    })
  }, []);

  //setto device scelto dall'utente e richiamo componenti per visualizzare i grafici
  const handlerGraph = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDevice(event.target.value);
    //const foundArray = JSON.parse(localStorage.getItem("resDataActiveEnergy") || "[]").find((array: any[]) => array.some((element) => element.deviceId === event.target.value));
  };

  return (
    <>
      <div>
        <div>
          <MenuDesktop pageValue={2} menu={showing} />
          <MenuMobile menu={showing} />
        </div>
        <div className="monitoraggio-content">
          <div className="div_header">
            <div className="div_text-header">
              <h1 id="text-start">Monitoraggio</h1>
              <h4 id="secondary-text">Cosa sta succedendo?</h4>
            </div>
            <div className="div_select-devices">
              {devices.length === 0 ? (
                <p>Loading data...</p>
              ) : (
                <select className="select" onChange={handlerGraph}>
                  <option className="select-item">Scegli un dispositivo..</option>
                  {devices.map((device) => (
                    <option className="select-item" key={device.id} value={device.id}>
                      {device.id}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className={selectedDevice?.startsWith("c2g-") ? "" : "charts-container disabled"}>
            <div className="charts">
              <div className="left-side">
                <GraphPowerInstant deviceId={selectedDevice} />
              </div>
              <div id="div-last-hour" className="right-side">
                <GraphLastHour device={selectedDevice} />
              </div>
            </div>
            <h4 id="secondary-text">Cosa &egrave; successo ieri?</h4>
            <div className="charts">
              <GraphYesterday device={selectedDevice} />
            </div>
            <h4 id="secondary-text" className="isto-text">Cosa è successo una settimana fa?</h4>
            <div className="charts">
              <IstogrammaWeek device={selectedDevice} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
