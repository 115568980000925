import React, { useState } from "react";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";

export default function CER() {
  const [showing, setShowing] = useState(false);

  const setMenu = () => {
    let box: any | undefined | null;
    box = document.querySelector("body");

    let width = box.offsetWidth;

    if (width > 1000) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  };

  setTimeout(function () {
    setMenu();
  }, 1);

  window.onresize = () => {
    setMenu();
    // window.location.reload();
  };

  return (
    <>
      <MenuDesktop pageValue={1} menu={showing} />
      <MenuMobile menu={showing} />
      <div>CER</div>
    </>
  );
}
