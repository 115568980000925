import { useEffect, useState } from "react";

import ControlValue from "../OthersComponents/ControlValue";
import infoImg from "../../images/info.svg";
import { Line } from "react-chartjs-2";
import MessageService from "../../services/messages.service";
import Energy from "../../models/Energy";
import moment from "moment";

import valori_produzione from '../../images'

export default function GraphYesterday(selectedDevice: any) {
  const messageService = new MessageService();

  const [labels, setLabels] = useState<Array<String>>([]);
  const [values, setValues] = useState<(number | undefined)[]>([]);
  const [dataChart, setDataChart] = useState<any>();
  const [options, setOptions] = useState<any>({});

  const [OldValues, setOldValues] = useState<(number | undefined)[]>([]);
  const [isSevenLine, setSevenLine] = useState(false);
  const [isMediaSelected, setMediaSelected] = useState(false);
  const [avgValues, setAvgValues] = useState<(number | undefined)[]>([]);

  const [suggestedMax, setMax] = useState(1000);

  const resDataIntantPower: Energy[][] = [];
  const Kwh_Cost = 0.00028; // DA CAMBIARE valore moltiplicatore 
  const Kwh_Cost_prod = 0.00014;

  //RICAVO GIORNO PRECEDENTE
  const today = new Date();
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 2, 0, 0, 0);
  const todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 2, 0, 0, 0);
  const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 2, 0, 0, 0);
  const sixDays = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6, 2, 0, 0, 0);

  // --- Bottoni di switch Euro-Watt e Watt-KiloWatt ---
  const [Euro_Watt_yesterday, setEuro_Watt_Y] = useState(0);
  const [Euro_Watt_prod, setEuro_Watt_P] = useState(0);

  const gestisciWatt_Euro_Y = (valore: any) => {
    setEuro_Watt_Y(valore);
  };

  const gestisciWatt_Euro_P = (valore: any) => {
    setEuro_Watt_P(valore);
  };

  /**
   * I successivi dati sono statici e utili solo per costruire il grafico di produzione dell'impianto.
   * L'endpoint da cui prelevare questi dati non esiste, perciò bisogna usare dei dati fittizzi per ora.
   */
  const produzioneValues = [0, 0, 208, 154, 86, 42, 18, 14, 23, 26, 0, 0];
  const produzioneValuesWeek = [0, 0, 6, 256, 0, 0, 23, 0, 0, 16, 10, 0];
  const produzioneLabels = ["01/01-06:00", "01/01-07:00", "01/01-08:00", "01/01-09:00", "01/01-10:00", "01/01-11:00", "01/01-12:00", "01/01-13:00", "01/01-14:00", "01/01-15:00", "01/01-16:00", "01/01-17:00"];

  const [monthProd, setMonthProd] = useState<string>();
  const [dayProd, setDayProd] = useState<string>();
  const [dayWeekProd, setDayWeekProd] = useState<string>();
  const [monthWeekProd, setMonthWeekProd] = useState<string>();

  const [valuesProd, setValuesProd] = useState<(number | undefined)[]>();
  const [valuesProdWeek, setValuesProdWeek] = useState<(number | undefined)[]>();
  const [averageProd, setAverageProd] = useState<(number | undefined)[]>();
  const [labelsProd, setLabelsProd] = useState<string[]>();

  const [dataChartProd, setDataChartProd] = useState<any>();
  const [optionsProd, setOptionsProd] = useState<any>({});

  const [isSevenLineProd, setSevenLineProd] = useState(false);
  const [isMediaProd, setMediaProd] = useState(false);
  const [suggestedMaxProd, setSuggestedMaxProd] = useState(1000);
  const [maxToRemember, setMaxRemember] = useState(1000);
 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // Dati grafico 7 GIORNI PRIMA
  const sevenLine = {
    name: "sevenDays",
    tension: 0.5,
    data: OldValues,
    fill: true,
    backgroundColor: "rgb(153, 204, 51, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(153, 204, 51)",
    pointBackgroundColor: "rgb(153, 204, 51)",
    pointBorderColor: "rgb(153, 204, 51)",
    pointHoverBackgroundColor: "#003366",
    pointHoverBorderColor: "#003366",
    pointRadius: 2,
  };

  // Dati grafico 7 GIORNI PRIMA PRODUZIONE
  const sevenLineProd = {
    name: "sevenDaysProd",
    tension: 0.5,
    data: valuesProdWeek,
    fill: true,
    backgroundColor: "rgb(153, 204, 51, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(153, 204, 51)",
    pointBackgroundColor: "rgb(153, 204, 51)",
    pointBorderColor: "rgb(153, 204, 51)",
    pointHoverBackgroundColor: "#003366",
    pointHoverBorderColor: "#003366",
    pointRadius: 4,
    pointHoverRadius: 10
  };

  // Dati grafico MEDIA 
  const media = {
    name: "Media",
    tension: 0.5,
    data: avgValues,
    fill: true,
    backgroundColor: "rgb(96, 165, 221, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(96, 165, 221)",
    pointBackgroundColor: "rgb(96, 165, 221)",
    pointBorderColor: "rgb(96, 165, 221)",
    pointHoverBackgroundColor: "rgba(0, 51, 102, 0.5)",
    pointHoverBorderColor: "rgba(0, 51, 102)",
    pointRadius: 2,
  };

  // Dati grafico MEDIA PRODUZIONE
  const mediaProd = {
    name: "mediaProd",
    tension: 0.5,
    data: averageProd,
    fill: true,
    backgroundColor: "rgb(96, 165, 221, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(96, 165, 221)",
    pointBackgroundColor: "rgb(96, 165, 221)",
    pointBorderColor: "rgb(96, 165, 221)",
    pointHoverBackgroundColor: "rgba(0, 51, 102, 0.5)",
    pointHoverBorderColor: "rgba(0, 51, 102)",
    pointRadius: 4,
    pointHoverRadius: 10
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  /**
   *  GRAFICO SETTIMANALE 
   */

  // RICHIESTA DATI PER GRAFICO
  useEffect(() => {
    if (selectedDevice.device != undefined) {
      //----- LINEA DI IERI -----
      let body = {
        from: yesterday,
        to: todayMidnight,
        deviceId: selectedDevice.device,
      };
      try {
        messageService.getActiveEnergy(JSON.stringify(body)).then((IntantPowerObject: Array<Energy> | null) => {
          if (IntantPowerObject) {
            //console.log(IntantPowerObject);
            resDataIntantPower.push(IntantPowerObject);
            localStorage.setItem("resDataIntantPower", JSON.stringify(IntantPowerObject));

            const yestVal = IntantPowerObject?.map((item: any) => item?.InstantPower);
            setValues(yestVal);
            
            let max : number = 0;
            yestVal.forEach((value : number) =>{
              if(value > max) max = value;
            })
            setMaxRemember(max);
            setSuggestedMaxProd(max);


            let month : string = moment.utc(IntantPowerObject[0]?.ts).format("MM");
            let day : string = moment.utc(IntantPowerObject[0]?.ts).format("DD");

            setMonthProd(month);
            setDayProd(day);
            setLabels(IntantPowerObject?.map((item: any) => moment.utc(item.ts).format("HH:mm")));

            //----- LINEA SETTE GIORNI FA -----
            body = {
              from: oneWeekAgo,
              to: sixDays,
              deviceId: selectedDevice.device,
            };
            try {
              messageService.getActiveEnergy(JSON.stringify(body)).then((IntantPowerObject: Array<Energy> | null) => {
                if (IntantPowerObject) {
                  //console.log(IntantPowerObject);
                  resDataIntantPower.push(IntantPowerObject);
                  localStorage.setItem("resDataIntantPower", JSON.stringify(IntantPowerObject));

                  const oldVal = IntantPowerObject?.map((item: any) => item?.InstantPower);
                  setOldValues(oldVal);

                  month = moment.utc(IntantPowerObject[0]?.ts).format("MM");
                  day = moment.utc(IntantPowerObject[0]?.ts).format("DD");

                  setMonthWeekProd(month);
                  setDayWeekProd(day);

                  //setto la media
                  const avgValues: number[] = [];
                  for (let i = 0; i <= yestVal.length; i++) {
                    avgValues.push((yestVal[i] + oldVal[i]) / 2);
                  }
                  setAvgValues(avgValues);
                }
              });
            } catch (exception) {
              console.log("Error getIntantPower: " + exception);
            }
          }
        });
      } catch (exception) {
        console.log("Error getIntantPower: " + exception);
      }
    }
  }, [selectedDevice.device]);

  // SETTO GRAFICO
  useEffect(() => {
    setDataChart({
      labels,
      datasets: [
        {
          tension: 0.5,
          backgroundColor: "rgba(0, 51, 102, 0.2)", // Colore di sfondo delle tooltips
          data: values,
          fill: true,
          borderColor: "#003366",
          pointBackgroundColor: "#003366",
          pointBorderColor: "#003366",
          pointHoverBackgroundColor: "rgb(153, 204, 51)",
          pointHoverBorderColor: "rgb(153, 204, 51)",
          pointRadius: 2,
        },
      ],
    });

    setOptions({
      maintainAspectRatio: false,
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: suggestedMax,
          ticks: {
            precision: 0, // Mostra cifre senza decimali
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2, // Spessore del bordo della linea
        },
      },
      plugins: {
        tooltip: {
          displayColors: false, // Nasconde il quadrato dei colori
          titleAlign: "center", // Allineamento del titolo delle tooltips
          bodyAlign: "center", // Allineamento del testo delle tooltips
          titleFont: {
            weight: "bold", // Titolo in grassetto
            align: "center",
          },
          bodyFont: {
            weight: "bold",
            size: 20, // Dimensione del testo
          },
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += context.parsed.y + (Euro_Watt_yesterday ? " €" : " W");
              }
              return label;
            },
          },
        },
        legend: {
          display: false, // Nasconde la legenda
        },
      },
    });
  }, [labels, values]);

  // CONVERSIONE EURO - WATT
  useEffect(()=>{
    if(selectedDevice.device != undefined){
      let arrayVal : number[]  = [];
      let arrayValWeek : number[]  = [];
      let avg : number[] = [];

      values.forEach((value : number | undefined) => arrayVal.push(convertEuroWatt(value!, Euro_Watt_yesterday, Kwh_Cost)));
      OldValues.forEach((value : number | undefined) => arrayValWeek.push(convertEuroWatt(value!, Euro_Watt_yesterday, Kwh_Cost)));

      for(let i = 0; i < arrayVal.length; i++){
        avg.push(parseFloat(((arrayVal[i] + arrayValWeek[i])/2).toFixed(4)));
      }

      setValues(arrayVal);
      setOldValues(arrayValWeek);
      setAvgValues(avg);
    }
  }, [Euro_Watt_yesterday])

 // AGGIUNTA GRAFICO 7 GIORNI PRIMA
 const addSevenDays = () => {
  setSevenLine(!isSevenLine);
  if (!isSevenLine) {
    setDataChart((prevData: any) => ({
      ...prevData,
      datasets: [...prevData.datasets, sevenLine],
    }));
  } else {
    /* --- Chiamo la funzione che mi trova l'indice esatto del grafico che voglio far sparire --- */
    const toDelete=findIndex("sevenDays", 0);

    setDataChart((prevData: any) => ({
      ...prevData,
      datasets: prevData.datasets.filter((_: any, index: any) => index !== toDelete),
    }));
  }
};

// AGGIUNTA GRAFICO MEDIA
const addMedia = () => {
  setMediaSelected(!isMediaSelected);

  if (!isMediaSelected) {
    setDataChart((prevData: any) => ({
      ...prevData,
      //labels: mediaLabels,
      datasets: [...prevData.datasets, media],
    }));
  } else {
     /* --- Chiamo la funzione che mi trova l'indice esatto del grafico che voglio far sparire --- */
     const toDelete=findIndex("Media", 0);

    setDataChart((prevData: any) => ({
      ...prevData,
      datasets: prevData.datasets.filter((_: any, index: any) => index !== toDelete),
    }));
  }
};

  // PERMANENZA GRAFICO 7 GIORNI PRIMA
  /* --- Il grafico dei 7 giorni prima RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isSevenLine){
      const toDelete=findIndex("sevenDays", 0);

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, sevenLine],
      }));
    }
  }, [OldValues])

  // PERMANENZA GRAFICO MEDIA
  /* --- Il grafico della media prima RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isMediaSelected){
      const toDelete=findIndex("Media", 0);

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, media],
      }));
    }
  }, [avgValues])

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  /**
   *  GRAFICO PRODUZIONE IMPIANTO
   */

  // SETTO DATI GRAFICO PRODUZIONE IMPIANTO
  useEffect(()=>{
    if(selectedDevice.device != undefined && monthWeekProd != undefined && dayWeekProd != undefined){

      let index : number = 0;
      let labels : string[] = [];
      let values : number[] = [];
      let avg : number[] = [];

      // --- VALORI GIORNO PRIMA ---
      for(let i = 0; i < valori_produzione.length; i++){
        if(valori_produzione[i][0] == monthProd && valori_produzione[i][1] == dayProd){
          index = i;
          break;
        }
      }
      for(let i = 1; i < 26; i++){
        labels.push(valori_produzione[index+i][2]+":00");
        values.push(parseInt(valori_produzione[index+i][3]));
      }
      setValuesProd(values);
      setLabelsProd(labels);

      // --- VALORI 7 GIORNI PRIMA ---
      let oldValues : number[] = [];

      for(let i = 0; i < valori_produzione.length; i++){
        if(valori_produzione[i][0] == monthWeekProd && valori_produzione[i][1] == dayWeekProd){
          index = i;
          break;
        }
      }
      for(let i = 1; i < 26; i++){
        oldValues.push(parseInt(valori_produzione[index+i][3]));
      }
      setValuesProdWeek(oldValues);

      // --- VALORI MEDIA ---
      for(let i = 0; i < values.length; i++){
        avg.push(((values[i] + oldValues[i]) / 2));
      }
      setAverageProd(avg);
    }
  }, [monthWeekProd, dayWeekProd])


  // SETTO GRAFICO
  useEffect(()=>{
    let labels = labelsProd;
    setDataChartProd({
      labels,
      datasets: [
        {
          tension: 0.5,
          backgroundColor: "rgba(252, 245, 95, 0.5)",
          data: valuesProd,
          fill: true,
          borderColor: "rgba(240, 225, 75)",
          pointBackgroundColor: "rgba(240, 225, 75)",
          pointBorderColor: "rgba(252, 245, 95)",
          pointHoverBackgroundColor: "#003366",
          pointHoverBorderColor: "#003366",
          pointRadius: 4,
          pointHoverRadius: 10,
        },
      ],
    });

    setOptionsProd({
      maintainAspectRatio: false,
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: suggestedMaxProd,
          ticks: {
            precision: 0, // Mostra cifre senza decimali
          },
        },
      },
      elements: {
        line: {
          borderWidth: 4, // Spessore del bordo della linea
        },
      },
      plugins: {
        tooltip: {
          displayColors: false, // Nasconde il quadrato dei colori
          titleAlign: "center", // Allineamento del titolo delle tooltips
          bodyAlign: "center", // Allineamento del testo delle tooltips
          titleFont: {
            weight: "bold", // Titolo in grassetto
            align: "center",
          },
          bodyFont: {
            weight: "bold",
            size: 20, // Dimensione del testo
          },
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += context.parsed.y + (Euro_Watt_prod ? " €" : " W");
              }
              return label;
            },
          },
        },
        legend: {
          display: false, // Nasconde la legenda
        },
      },
    });
  }, [valuesProd, labelsProd, suggestedMaxProd])

  // AGGIUNTA GRAFICO 7 GIORNI PRIMA
  const addSevenDaysProd = () => {
    setSevenLineProd(!isSevenLineProd);
    if (!isSevenLineProd) {
      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, sevenLineProd],
      }));
    } else {
      /* --- Chiamo la funzione che mi trova l'indice esatto del grafico che voglio far sparire --- */
      const toDelete=findIndex("sevenDaysProd", 1);
      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== toDelete),
      }));
    }
  };

  // AGGIUNTA GRAFICO 7 GIORNI PRIMA
  const addMediaProd = () => {
    setMediaProd(!isMediaProd);
    if (!isMediaProd) {
      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, mediaProd],
      }));
    } else {
      /* --- Chiamo la funzione che mi trova l'indice esatto del grafico che voglio far sparire --- */
      const toDelete=findIndex("mediaProd", 1);

      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== toDelete),
      }));
    }
  };

  // PERMANENZA GRAFICO 7 GIORNI PRIMA
  /* --- Il grafico dei 7 giorni prima RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isSevenLineProd){
      const toDelete=findIndex("sevenDaysProd", 0);

      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, sevenLineProd],
      }));
    }
  }, [valuesProdWeek])

  // PERMANENZA GRAFICO MEDIA
  /* --- Il grafico della media prima RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isMediaProd){
      const toDelete=findIndex("mediaProd", 0);

      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChartProd((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, mediaProd],
      }));
    }
  }, [averageProd])

  // CONVERSIONE EURO - WATT 
  useEffect(()=>{
    // Aggiunta statica dei valori per il grafico di produzione (da implementare quando ci sarà l'endpoint da cui prelevare i dati)
    if(selectedDevice.device != undefined){
      let arrayProd : number[] = [];
      let arrayProdWeek : number[] = [];
      let avg : number[] = [];

      valuesProd!.forEach((value : number | undefined) => arrayProd.push(convertEuroWatt(value!, Euro_Watt_prod, Kwh_Cost_prod)));
      valuesProdWeek!.forEach((value : number | undefined) => arrayProdWeek.push(convertEuroWatt(value!, Euro_Watt_prod, Kwh_Cost_prod)));

      for(let i = 0; i < arrayProd.length; i++){
        avg.push(parseFloat(((arrayProd[i] + arrayProdWeek[i])/2).toFixed(4)));
      }
      
      setValuesProd(arrayProd);
      setValuesProdWeek(arrayProdWeek);
      setAverageProd(avg);
    }
  }, [Euro_Watt_prod]);
  
  
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
 
  // TROVA INDICE GRAFICO
  function findIndex(graph: string, type : number){
    /**
     * type = 1 PRODUZIONE
     * type = 0 SETTIMANALE
     */
    let index;
    let data;
    if(type){
      data = dataChartProd;
    }else{
      data = dataChart;
    }

    data.datasets.forEach((e: any, v: number) => {
      if(e.name == graph){
        index = v;
      }
    })

    return index;
  }

  // FUNZIONE CONVERSIONE EURO - WATT
  const convertEuroWatt = (powerValue : number, flagEuro_Watt : number, Kwh_value : number) =>{
    let value : number = powerValue;
    if(flagEuro_Watt){
      value = parseFloat((powerValue*Kwh_value).toFixed(4));
      setMax(1);
      setSuggestedMaxProd(1);
    }else{
      value = Math.floor(powerValue / Kwh_value);
      setMax(1000);
      setSuggestedMaxProd(maxToRemember);
    }

    return value;
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------  

  return (
    <>
    <div>
      <div className="left-side-lastDay">
        <div className="controlValueYest-div">
          <ControlValue onValoreWatt_Euro={gestisciWatt_Euro_P} changeMedia={addMediaProd} changeOther={addSevenDaysProd} typeOther={1}/>
        </div>
      </div>
      <div className="left-side-lastDay">
        <div className="controlValueYest-div">
          <ControlValue onValoreWatt_Euro={gestisciWatt_Euro_Y} changeMedia={addMedia} changeOther={addSevenDays} typeOther={1}/>
        </div>
      </div>
    </div>
      <div className="yest-charts-div">
        <div id="div-prod" className="right-side CER-chart ">
          <h5>Produzione dell'impianto <div className="hover-image-container">
                <img src={infoImg} className="container-track-info hover-image" />
                <div className="hover-text">
                  <span className="hover-text-inner">
                    <b>Cos'è questa cosa?</b>
                    <br />
                    <p>Questo grafico fa riferimento alla <b>produzione</b> di energia rinnovabile<br/>
                       di un <b>impianto fotovoltaico</b> durante il corso di una intera giornata.</p>
                  </span>
                </div>
            </div></h5>
          
          <div id="graph-prod" className="line-graph">
            { dataChartProd && <Line id="myChartProd" data={dataChartProd} options={optionsProd}></Line> }
          </div>
        </div>
        <div className="right-side CER-chart ">
          <h5>I tuoi consumi di ieri</h5>
          <div className="line-graph" id="div-yesterday" >{dataChart && <Line id="myChart" data={dataChart} options={options}></Line>}</div>
        </div>
      </div>
    </>
  );
}
