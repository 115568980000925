import React from 'react'
import MenuDesktop from './MenuDesktop'

export default function Settings() {
  return (
    <>
      <MenuDesktop/>
      <div>Settings</div>
    </>
  )
}
