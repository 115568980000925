import { useEffect, useState } from "react";
import "../../styles/Charts/graphLastHour.css";

import MessageService from "../../services/messages.service";

import Energy from "../../models/Energy";

import { Line } from "react-chartjs-2";
import moment from "moment";
import VerticalScroll from "../OthersComponents/VerticalScroll";
import ControlValue from "../OthersComponents/ControlValue";


export default function GraphLastHour(selectedDevice: any) {
  const messageService = new MessageService();

  const [labels, setLabels] = useState<Array<String>>([]);
  const [values, setValues] = useState<(number | undefined)[]>([]);

  const [dataChart, setDataChart] = useState<any>();
  const [options, setOptions] = useState<any>({});

  const [valuesOLD, setValuesOLD] = useState<(number | undefined)[]>([]);

  const [isCheckedLinea, setCheckedLinea] = useState(false);
  const [isMediaSelected, setMediaSelected] = useState(false);
  const [avgValues, setAvgValues] = useState<(number | undefined)[]>([]);

  const [suggestedMax, setMax] = useState(1000); // Mi serve per cambiare il valore massimo suggerito del grafico a seconda dell'unità di misura
  const [clickedPower, setClicked] = useState<(number | undefined)>(0); // Valore di Potenza selezionato nel grafico che mando dinamicamente alla lista di elettrodomestici

  const resDataIntantPower: Energy[][] = [];
  const Kwh_Cost = 0.00028; // DA CAMBIARE valore moltiplicatore 

  //RICAVO ORA CORRENTE E DA QUANDO VOGLIO FARE LA CHIAMATA
  const dataAttuale = new Date();

  // Sottrai 2 ore in millisecondi (essendo il grafico dell'ultima ora non aveva senso raccogliere più di 2 ore)
  const requestISODate = new Date(dataAttuale.getTime() - 2 * 60 * 60 * 1000).toISOString(); 

  // --- Bottoni di switch Euro-Watt e Watt-KiloWatt ---
  const [Euro_Watt, setEuro_Watt] = useState(0);
  const [Watt_Kilo, setWatt_Kilo] = useState(0);

  // --- Valori iniziati recuperati e salvati ==> serve quando ritorno a Watt come unità di misura ---  
  const [wattValues, setWattValues] = useState<number[]>([]);
  const [wattValuesOld, setWattValuesOld] = useState<number[]>([]);
  const [wattAvg, setWattAvg] = useState<number[]>([]);

  /* --- Funzioni che cambiano valore al bottone corrispondente quando premuto --- */
  const gestisciWatt_Euro = (valore: any) => {
    setEuro_Watt(valore);
  };

  const gestisciWatt_Kilo = (valore: any) =>{
    setWatt_Kilo(valore);
  }

 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // Dati grafico ORA PRIMA
  const lineaPrecedente = {
    name: "lastHour",
    tension: 0.5,
    data: valuesOLD,
    fill: true,
    backgroundColor: "rgb(153, 204, 51, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(153, 204, 51)",
    pointBackgroundColor: "rgb(153, 204, 51)",
    pointBorderColor: "rgb(153, 204, 51)",
    pointHoverBackgroundColor: "#003366",
    pointHoverBorderColor: "#003366",
    pointRadius: 4,
    pointHoverRadius: 10
  };

  // Dati grafico MEDIA
  const media = {
    name: "Media",
    tension: 0.5,
    data: avgValues,
    fill: true,
    backgroundColor: "rgb(96, 165, 221, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(96, 165, 221)",
    pointBackgroundColor: "rgb(96, 165, 221)",
    pointBorderColor: "rgb(96, 165, 221)",
    pointHoverBackgroundColor: "rgba(0, 51, 102, 0.5)",
    pointHoverBorderColor: "rgba(0, 51, 102)",
    pointRadius: 4,
    pointHoverRadius: 10,
    borderDash: [7, 7],
    borderWidth: 1,
  };

 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
 // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-------- LINEA ORA ATTUALE ---------
  //1) CHIAMATA PER I DATI DEL GRAFICO DELL'ULTIMA ORA
  useEffect(() => {
    if (selectedDevice.device != undefined) {
      let body = {
        from: requestISODate,
        to: dataAttuale.toISOString(),
        deviceId: selectedDevice.device,
      };

      try {
        messageService.getActiveEnergy(JSON.stringify(body)).then((IntantPowerObject: Array<Energy> | null) => {
          if (IntantPowerObject) {
            //console.log(IntantPowerObject);
            resDataIntantPower.push(IntantPowerObject);
            localStorage.setItem("resDataIntantPower", JSON.stringify(IntantPowerObject));

            //setto i valori attuali in base ai bottoni di switch
            const values = IntantPowerObject?.slice(-4).map((item: any) => item?.InstantPower);
            setLabels(IntantPowerObject?.map((item: any) => moment.utc(item.ts).format("HH:mm")).slice(-4));
            setWattValues(values);
            setValues(values);

            //setto la linea ora precedente in base ai bottoni di switch
            const oldValue = IntantPowerObject?.slice(IntantPowerObject.length - 8, IntantPowerObject.length - 4).map((obj: any) => obj.InstantPower);

            setWattValuesOld(oldValue);
            setValuesOLD(oldValue);

            //setto la media
            const avgValues: number[] = [];
            for (let i = 0; i < values.length; i++) {
              avgValues.push((values[i] + oldValue[i]) / 2);
            }
            setWattAvg(avgValues);
            setAvgValues(avgValues);
          }
        });
      } catch (exception) {
        console.log("Error getIntantPower: " + exception);
      }
    }
  }, [selectedDevice.device]);

  //2) setto il grafico  -> solo grafica presentazione -> si usa chart.js
  useEffect(() => {
    setDataChart({
      labels,
      datasets: [
        {
          tension: 0.5,
          backgroundColor: "rgba(0, 51, 102, 0.2)", // Colore di sfondo delle tooltips
          data: values,
          fill: true,
          borderColor: "#003366",
          pointBackgroundColor: "#003366",
          pointHoverBackgroundColor: "rgb(153, 204, 51)",
          pointHoverBorderColor: "rgb(153, 204, 51)",
          pointBorderColor: "#003366",
          pointRadius: 4,
          pointHoverRadius: 10,
        },
      ],
    });
  }, [labels, values]);

  
  /*  Ho separato il setOptions da setDataChart poichè ho bisogno di sapere
      di quale grafico (più precisamente di quale punto) l'utente vuole sapere la potenza 
      erogata in un determinato momento MA per farlo devo aggiornare le opzioni ogni volta che
      l'utente seleziona o deseleziona il bottone per far apparire gli altri grafici.

      A fronte di ciò, se avessi lasciato il setOptions dov'era prima avrei dovuto aggiornare
      assieme alle opzioni anche il dataset, abbandonando l'efficienza della scelta presa
  */
  useEffect(()=>{
    setOptions({
      onClick : (e : MouseEvent, element : any) =>{
        if(element.length > 0){
          let index = element[0].index;
          let datasetIndex = element[0].datasetIndex;

          let mediaIndex = findIndex("Media");
          let lastHourIndex = findIndex("lastHour");

          // Scopro quale grafico ha cliccato l'utente --> uso la Potenza del punto del grafico scelto
          let value : number | undefined = 0;
          if(datasetIndex == mediaIndex){
            value = avgValues[index];
          }else if(datasetIndex == lastHourIndex){
            value = valuesOLD[index];
          }else{
            value = values[index];
          }

          // Se clicco sul grafico mentre mi mostra i valori in Euro o KW devo cambiare il valore che do alla lista
          if(value != undefined && value !== Math.floor(value) && Euro_Watt){
            value = parseFloat((value/Kwh_Cost).toFixed(4));
            console.log(value);
          }else if(value != undefined && value !== Math.floor(value) && Watt_Kilo && !Euro_Watt){
            value = value * 1000;
            console.log(value);
          }

          setClicked(value);
        }else{
          setClicked(0); // Se clicco altro che non sia un punto allora resetto la lista a 0
        }
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: suggestedMax,
          ticks: {
            precision: 0, // Mostra cifre senza decimali
          },
        },
      },
      elements: {
        line: {
          borderWidth: 4, // Spessore del bordo della linea
        },
      },
      plugins: {
        tooltip: {
          displayColors: false, // Nasconde il quadrato dei colori
          titleAlign: "center", // Allineamento del titolo delle tooltips
          bodyAlign: "center", // Allineamento del testo delle tooltips
          titleFont: {
            weight: "bold", // Titolo in grassetto
            align: "center",
          },
          bodyFont: {
            weight: "bold",
            size: 20, // Dimensione del testo
          },
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                if(!Euro_Watt){
                  label += context.parsed.y + (Watt_Kilo ? " kW" : " W");
                }else{
                  label += context.parsed.y + (Euro_Watt ? " €" : " W");
                }
              }
              return label;
            },
          },
        },
        legend: {
          display: false, // Nasconde la legenda
        },
      },
    });
  }, [labels, values, isCheckedLinea, isMediaSelected]);

  // CONVERSIONE EURO - WATT
  useEffect(()=>{
    if(selectedDevice.device != undefined && !Watt_Kilo){      
      if(!Euro_Watt){

        setValues(wattValues);
        setValuesOLD(wattValuesOld);
        setAvgValues(wattAvg);
        setMax(1000);

      }else{

        let arrayVal : number[]  = [];
        let arrayValWeek : number[]  = [];
        let avg : number[] = [];

        values.forEach((value : number | undefined) => arrayVal.push(convertEuroWatt(value!)));
        valuesOLD.forEach((value : number | undefined) => arrayValWeek.push(convertEuroWatt(value!)));

        for(let i = 0; i < arrayVal.length; i++){
          avg.push(parseFloat((arrayVal[i] + arrayValWeek[i]/2).toFixed(4)));
        }

        setValues(arrayVal);
        setValuesOLD(arrayValWeek);
        setAvgValues(avg);

    }
  }
  }, [Euro_Watt])

  // CONVERSIONE WATT - KILOWATT
  useEffect(()=>{
    if(selectedDevice.device != undefined && !Euro_Watt){
      if(!Watt_Kilo){

        setValues(wattValues);
        setValuesOLD(wattValuesOld);
        setAvgValues(wattAvg);
        setMax(1000);

      }else{

        let arrayVal : number[]  = [];
        let arrayValWeek : number[]  = [];
        let avg : number[] = [];

        values.forEach((value : number | undefined) => arrayVal.push(convertWattKilo(value!)));
        valuesOLD.forEach((value : number | undefined) => arrayValWeek.push(convertWattKilo(value!)));

        for(let i = 0; i < arrayVal.length; i++){
          avg.push(parseFloat((arrayVal[i] + arrayValWeek[i]/2).toFixed(4)));
        }

        setValues(arrayVal);
        setValuesOLD(arrayValWeek);
        setAvgValues(avg);
        
      }
  }
  }, [Watt_Kilo])

  // AGGIUNTA GRAFICO ORA PRIMA
  const addLinea = (num: number) => {
    setCheckedLinea(!isCheckedLinea);
    if (!isCheckedLinea) {

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedente],
      }));

    } else {
      /* --- Chiamo la funzione che mi trova l'indice esatto del grafico che voglio far sparire --- */
      const toDelete=findIndex("lastHour");

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));
    }
  };

  // AGGIUNTA GRAFICO MEDIA
  const addMedia = (num: number) => {
    setMediaSelected(!isMediaSelected);

    if (!isMediaSelected) {  
      setDataChart((prevData: any) => ({
        ...prevData,
        //labels: mediaLabels,
        datasets: [...prevData.datasets, media],
      }));

    } else {

       // Indice del grafico da far sparire
      const toDelete=findIndex("Media");

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== toDelete)
      }));
    }
  };

  // PERMANENZA GRAFICO ORA PRIMA
  /* --- Il grafico dell'ora prima RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isCheckedLinea){
      const toDelete=findIndex("lastHour");

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedente],
      }));
    }
  }, [valuesOLD])

  // PERMANENZA GRAFICO MEDIA
  /* --- Il grafico della media RIMANE anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isMediaSelected){
      const toDelete=findIndex("Media");

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != toDelete)
      }));

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, media],
      }));
    }
  }, [avgValues])

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // TROVA INDICE GRAFICO
  function findIndex(graph: string){
    let index;
    dataChart.datasets.forEach((e: any, v: number) => {
      if(e.name == graph){
        index = v;
      }
    })

    return index;
  }

  // FUNZIONE CONVERSIONE EURO - WATT
  const convertEuroWatt = (powerValue : number) =>{
    let value = parseFloat((powerValue*Kwh_Cost).toFixed(4));
    setMax(1);

    return value;
  }

  // FUNZIONE CONVERSIONE WATT - KILOWATT
  const convertWattKilo = (powerValue : number) => {
    let value = powerValue / 1000;
    setMax(1);

    return value;
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------  

  return (
    <div className="line-chart-div">
      <h5>Nelle ultime ore</h5>

      <div className="line-graph">{dataChart && <Line id="myChart" data={dataChart} options={options}></Line>}</div>

      <div className="lastHour-more">
        <div className="lastHour-info">
          <h5>Cosa poteva essere?</h5>
          <VerticalScroll button={Euro_Watt} power={clickedPower}/>
        </div>
        <div className="controlValue-div">
        <ControlValue onValoreWatt_Euro={gestisciWatt_Euro} onValoreWatt_Kilo={gestisciWatt_Kilo} changeMedia={addMedia} changeOther={addLinea} typeOther={0}/>
        </div>
      </div>
    </div>
  );
}
