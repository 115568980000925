import "bootstrap/dist/css/bootstrap.min.css";

import ominoImg from "../images/ominoLogin.svg";
import logoImg from "../images/logo.svg";
import eyePass from "../images/pass-eye.svg";
import noeyePass from "../images/nopass-eye.svg";
import dangerImg from "../images/pericoloPass.svg";
import circlesImg from "../images/circles.svg";

import "../styles/login.css";
import "../styles/root.css";

import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Button, Container, Col } from "react-bootstrap";
import AuthService from "../services/auth.service";

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorAccess, setErrorAccess] = useState(0);
  const [style, setStyle] = useState("not-error");
  const [check, setCheck] = useState(false);
  const userAuth = new AuthService();

  /* --- Cambiare stile credenziali errate --- */
  const changeStyle = () => {
    setStyle("error-text");
    setErrorAccess(errorAccess + 1);
  };

  /* --- Visualizzare o meno la password --- */
  const togglePassword = () => {
    setPasswordShown(!passwordShown); //When the handler is invoked inverse the boolean state of passwordShown
  };

  /* --- Cambiare lo stato di "Tienimi Connesso" --- */
  const toggleStayConn = () =>{
    setCheck(!check); // Quando l'handler viene invocato inverto il valore del boolean inizialmente impostato su false
  };

  /* --- Credenziali valide --- */
  const validate = () => {
    let result = true;
    if ((username === "" || username == null) && errorAccess < 1) {
      result = false;
      changeStyle();
    }
    if ((password === "" || password == null) && errorAccess < 1) {
      result = false;
      changeStyle();
    }
    return result;
  };

  /* --- Login --- */
  const log = async (event: any) => {
    event.preventDefault();
    if (validate()) {
      try {
        userAuth.login(username, password, check).then((ok) => {
          if (ok) {
            setErrorAccess(0);
            navigate("/home");
            navigate(0);
          } 
          else {
            changeStyle();
          }
        });
      } catch (exception) {
        console.log("Was not possible to login, error: " + exception);
      }
    }
  };

  return (
    <>
      <div className="background-images">
        <img src={circlesImg} className="circles" />
        <img src={ominoImg} className="omino" />
      </div>
      <Container>
        <Row className="row-as">
          <Col>
            <Row className="logoImg">
              <Col>
                <img src={logoImg} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Eccoti di nuovo</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="title-CER">
                  La Comunità Energetica <br /> ci aspetta!
                </h2>
              </Col>
            </Row>
          </Col>
          <Row className="login-box">
            <form onSubmit={log}>
              <Col>
                <Row>
                  <Col>
                    <h4 className="text-login">Fai l'accesso</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className={style} style={{ color: errorAccess ? "red" : "var(--primary-color)", display: errorAccess ? "block" : "none" }}>
                      Ops, sembra che i dati non siano corretti
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <fieldset style={{ border: errorAccess ? "2px solid red" : "2px solid var(--secondary-color)" }}>
                      <legend className="float-none w-auto" style={{ color: errorAccess ? "red" : "var(--secondary-color)" }}>
                        Nome Utente
                      </legend>
                      <input className="input-log" type="text" onChange={(e) => setUsername(e.target.value)} placeholder="Inserisci qui il tuo nome utente"></input>
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <fieldset style={{ border: errorAccess ? "2px solid red" : "2px solid var(--secondary-color)" }}>
                      <legend className="float-none w-auto" style={{ color: errorAccess ? "red" : "var(--secondary-color)" }}>
                        Password
                      </legend>
                      <input className="input-log" type={passwordShown ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} placeholder="Inserisci qui la tua password" />
                      <img src={passwordShown ? noeyePass : eyePass} className="eye-pass" onClick={togglePassword} />
                    </fieldset>
                  </Col>
                </Row>
                <Row className="extra-settings">
                  <Col className="remember-me">
                    <label className="still-connect">
                      <input className="checkbox-button" type="checkbox" onClick={toggleStayConn} />
                      <label className="lab-check">Tienimi connesso</label>
                    </label>
                  </Col>
                  <Col className="forgot-pass">
                    <label className="lab-forgot">
                      <Link to="/recupPass" className="text-forgot-pass">
                        Recupera la password
                      </Link>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button className="button-login" type="submit">
                      <span className="text-button">Entra nell'app</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </form>
          </Row>
        </Row>
      </Container>
    </>
  );
}
export default Login;
