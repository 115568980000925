import React, { useState } from "react";
// import Chart from 'chart.js/auto';

import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import { Container, Row, Col } from "react-bootstrap";

import "../styles/controllo.css";
import "../styles/root.css";

export default function Bilancio() {
  const [showing, setShowing] = useState(false);

  const setMenu = () => {
    let box: any | undefined | null;
    box = document.querySelector("body");

    let width = box.offsetWidth;

    if (width > 1000) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  };

  setTimeout(function () {
    setMenu();
  }, 1);

  window.onresize = () => {
    setMenu();
    // window.location.reload();
  };

  return (
    <>
      <MenuDesktop pageValue={3} menu={showing} />
      <MenuMobile menu={showing} />
      <div>Controllo</div>
    </>
  );
}
