import WVZ from "../context/WVZ";
import jwt from "jwt-decode";

import JwtUser from "../models/User";
import { local } from "d3";
/**
 * The AuthService takes care of the login and logout procedures
 */
export class AuthService {

    private _token : string | undefined;

    get token() {
        return this._token;
    }
    set token(tk : string | undefined){
        this._token=tk;
    }

    /**
     * Sends to the backend API a login request invoking the auth/login endPoint.
     * If the login operation succeedes the API send back a token that is stored 
     * in the sessionStorage to allow application reload without the need to 
     * login again. The received token can be used to call all the protected 
     * endPoints
     * @param username The username
     * @param password The user password
     * @returns True if the login succeedes, otherwise false
     */
    public async login(username: string, password: string, stayConn: boolean):Promise<boolean> {
        const res = await fetch(WVZ.API_URL + "auth/login", {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json'
                    },
                body: JSON.stringify({"userName" : username, "userPass" : password})
            }
        );
        if(res.ok){
            this.logout();
            
            //salvataggio token
            const data : { token: string} = await res.json();
            this._token = data.token;
            sessionStorage.setItem(WVZ.SESSION_TOKEN, this._token);
            

            //salva utente loggato per poter accedere
            sessionStorage.setItem(WVZ.SESSION_TOKEN, data.token);
            const jwtUser : JwtUser = jwt(data.token);
            sessionStorage.setItem(WVZ.USER, JSON.stringify(jwtUser.user));
            
            // Salvo localmente utente loggato se ha scelto l'opzione Tienimi Connesso
            if(stayConn) {
                localStorage.setItem(WVZ.STAY_CONNECTED, 'true');
                localStorage.setItem(WVZ.SESSION_TOKEN, this._token);
                localStorage.setItem(WVZ.SESSION_TOKEN, data.token);
                localStorage.setItem(WVZ.USER, JSON.stringify(jwtUser.user));
            }
            return true;
        } else {
            this.logout();
            return false;
        }
    }
    
    /**
     * Removes the token from the localStorage, from the 
     * sessionStorage and from the auth service so that 
     * the user must login again
     */
    public logout(): void {
        sessionStorage.removeItem(WVZ.SESSION_TOKEN);
        localStorage.removeItem(WVZ.SESSION_TOKEN);
        localStorage.removeItem(WVZ.STAY_CONNECTED);
        this._token = undefined;
    }

 }

 export default AuthService;