import "bootstrap/dist/css/bootstrap.min.css";

import "../styles/menuDesktop.css";

import logoImg from "../images/logo.svg";
import profiloImg from "../images/profilo.svg";
import cerImg from "../images/cer.svg";
import bilancioImg from "../images/bilancio.svg";
import controlloImg from "../images/controllo.svg";
import settingsImg from "../images/impostazioni.svg";
import exitImg from "../images/exit.svg";

import Nav from "react-bootstrap/Nav";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";

export default function MenuDesktop(info: any) {
  const valu = info.menu;
  const number = info.pageValue;
  const userAuth = new AuthService();
  const navigate = useNavigate();

  // Quando l'utente clickerà l'immagine di logout effettua il logout vero e proprio
  const logout = async (event: any) =>{
    event.preventDefault();
    userAuth.logout();
    navigate("/"); //riporto l'utente alla pagina di login
  }

  var ids = [];
  ids[number] = "active";

  return (
    <>
      <div className="menuDesktop" style={{ display: valu ? "block" : "none" }}>
        <Link to="/home">
          <img src={logoImg} className="logo" alt="LogoImg" />
        </Link>
        <Nav className="items-menu">
          <Nav.Item className="item-menu" id={ids[0]}>
            <Nav.Link href="/profilo" className="profilo">
              <img src={profiloImg} className="PROFimg" />
              <p>Profilo</p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="item-menu" id={ids[1]}>
            <Nav.Link href="/cer" className="cer">
              <img src={cerImg} className="CERimg" />
              <p>CER</p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="item-menu" id={ids[3]}>
            <Nav.Link href="/bilancio" className="bil">
              <img src={bilancioImg} className="BILimg" />
              <p>Bilancio</p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="item-menu" id={ids[2]}>
            <Nav.Link href="/monitoraggio" className="controllo">
              <img src={controlloImg} className="CONTRLimg" />
              <p>Controllo</p>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="extra-items">
          <Nav.Item className="extra-item">
            <Nav.Link href="/settings" className="extra-item link">
              <img src={settingsImg} className="profImg" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="extra-item">
            <Nav.Link href="/" className="extra-item link">
              <img src={exitImg} className="profImg" onClick={logout}/>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </>
  );
}
