import { useEffect, useRef, useState } from "react";
import "../../styles/Charts/graphPowerInstant.css";
import "../../styles/verticalScroll.css";

import chroma from "chroma-js";

import infoImg from "../../images/info.svg";
import refreshImg from "../../images/refresh.svg";
import flashImg from "../../images/flash.svg";
import euroImg from "../../images/euro.svg";

import MessageService from "../../services/messages.service";
import SwitchButton from "../OthersComponents/SwitchButton";
import VerticalScroll from "../OthersComponents/VerticalScroll";
import Energy from "../../models/Energy";

export default function GraphPowerInstant(deviceId: any) {
  //Modifica bordo spessore grafico

  //----- Caratteristiche grafico ------
  const thickness = 14; //spessore bordo
  const animation = {
    duration: 100,
    easing: "linear",
  };
  const animationStartValue = 0;
  const progressBarRef = useRef<HTMLCanvasElement>(null);
  const emptyColor = "#E9EFF5";
  const borderRadius = 5;
  const colorScale = chroma.scale(["green", "yellow", "yellow", "red", "red"]).mode("lch").colors(20);
  //-----------------------------------

  const [trigger, setTrigger] = useState({name: ""}); //costruisco un trigger da usare quando premo il tasto di reload dei consumi
  const [oldValue, setOldValue] = useState(deviceId.deviceId);
  const [powerInstantValue, setPowerValue] = useState<any>("---"); // valore potenza in Watt
  const [powerInstantEuro, setPowerEuro] = useState<any>("---"); // valore potenza in Euro

  const messageService = new MessageService();
  const Kwh_Cost = 0.00028; // DA CAMBIARE valore moltiplicatore 

  //fare in modo che torni 0 prima che si modifichi il grafico

  if (deviceId.deviceId != oldValue) {
    setOldValue(deviceId.deviceId);
    setPowerValue("---");
  }

  //chiamata per getPower -> va cambiato in getActiveEnergy.instantPower
  useEffect(() => {
    const dataAttuale = new Date();
    const requestISODate = new Date(dataAttuale.getTime() - 1 * 60 * 60 * 1000).toISOString(); // Sottrai un'ora in millisecondi

    if (deviceId.deviceId != null) {
      let body = {
        from: requestISODate,
        deviceId: deviceId.deviceId,
      };

      messageService
        .getActiveEnergy(JSON.stringify(body))
        .then((result: Array<Energy> | null) => {
          console.log(result);
          if (result && result.length > 0) {
            setPowerValue(result[result.length - 1].InstantPower);
            setPowerEuro((result[result.length - 1].InstantPower * Kwh_Cost).toFixed(4)); 
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [deviceId, trigger]);

  //set del grafico È SOLO PRESENTAZIONE
  useEffect(() => {
    const canvas = progressBarRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const drawSemicircularProgressBar = (progressValue: number) => {
      const size = 230; //Modifica larghezza grafico
      const center = size / 2;
      const radius = center - (typeof thickness === "number" ? thickness : parseFloat(thickness));
      const startAngle = Math.PI * 0.85;
      const endAngle = Math.PI * 1.5 + (1300 / 3000) * (Math.PI * 1.5);

      canvas.width = size;
      canvas.height = size;

      ctx.clearRect(0, 0, size, size);

      // Draw empty semicircle
      ctx.strokeStyle = emptyColor;
      ctx.lineWidth = typeof thickness === "number" ? thickness : parseFloat(thickness);
      ctx.beginPath();
      ctx.arc(center, center, radius, startAngle, endAngle);
      ctx.stroke();

      // Draw filled border with gradient
      const gradient = ctx.createLinearGradient(0, 0, size, 0);
      colorScale.forEach((color, index) => {
        const stopPosition = index / (colorScale.length - 1);
        gradient.addColorStop(stopPosition, color);
      });

      ctx.strokeStyle = gradient;
      ctx.beginPath();
      ctx.arc(center, center, radius, startAngle, Math.min((animationStartValue / 3000) * (Math.PI * 1.5) + startAngle, endAngle), false);
      ctx.stroke();

      // Add border radius
      if (borderRadius) {
        ctx.lineCap = "round"; // Imposta la forma del capo del tratto come rotondo
        ctx.lineJoin = "round"; // Imposta la forma dell'unione dei tratti come rotondo
        ctx.lineWidth = 2 * (typeof thickness === "number" ? thickness : parseFloat(thickness)) * parseFloat(borderRadius.toString());

        const roundedStartAngle = startAngle - Math.PI / 2; // Sottrai 90 gradi all'angolo di inizio
        const roundedEndAngle = endAngle - Math.PI / 2; // Sottrai 90 gradi all'angolo di fine

        ctx.beginPath();
        ctx.arc(center, center, radius, roundedStartAngle, roundedEndAngle, false);
        ctx.stroke();
      }

      // Animate the progress bar
      const animationSteps = Math.ceil(((progressValue - animationStartValue) * animation.duration) / 1000); //Modifica velocità caricamento
      const animationInterval = animation.duration / animationSteps;
      let animatedValue = animationStartValue;

      const animate = () => {
        animatedValue += (progressValue - animationStartValue) / animationSteps;

        ctx.clearRect(0, 0, size, size);

        // Draw empty semicircle
        ctx.strokeStyle = emptyColor;
        ctx.lineWidth = typeof thickness === "number" ? thickness : parseFloat(thickness);
        ctx.beginPath();
        ctx.arc(center, center, radius, startAngle, endAngle);
        ctx.stroke();

        // Draw filled border with gradient
        ctx.strokeStyle = gradient;
        ctx.beginPath();
        ctx.arc(center, center, radius, startAngle, Math.min((animatedValue / 3000) * (Math.PI * 1.5) + startAngle, endAngle), false);
        ctx.stroke();

        if (animatedValue < progressValue) {
          requestAnimationFrame(animate);
        }
      };

      animate();
    };

    drawSemicircularProgressBar(powerInstantValue);
  }, [powerInstantValue, thickness, emptyColor, animation, animationStartValue]);

  //-----------------------------------------

  /* --- Refresh che richiama solo e soltanto la richiesta dei dispositivi all'API -> NO REFRESH INTERA PAGINA*/
  const handlerRefresh = () => {
    setTrigger({name: "trigger"});
  };

  const [valoreBottone, setValoreBottone] = useState("");

  //SERVE PER IL BOTTONE DI SWITCH
  const gestisciValoreBottone = (valore: any) => {
    setValoreBottone(valore);
  };

  return (
    <div className="instant-power-div">
      <h5>In questo momento</h5>
      <div className="container-tack">
        <canvas ref={progressBarRef} />
        <div className="container-track-consumo">
          <p id="consumi-text">consumi</p>
          <h2 id="consumi-value">
            {valoreBottone ? powerInstantEuro : powerInstantValue}
            {powerInstantValue && valoreBottone ? " € " : " W "}
            {powerInstantValue > 0 && (
              <div className="hover-image-container">
                <img src={infoImg} className="container-track-info hover-image" />
                <div className="hover-text">
                  <span className="hover-text-inner">
                    <b>Cos'è questa cosa?</b>
                    <br />
                    <p>Questo valore fa riferimento a quanto hai <b>consumato nell’ultimo istante</b>.<br/>
                      Sono Watt perché si tratta della <b>Potenza</b>: ossia quanto consumi in un preciso momento, come se fosse la fotografia di un istante.<br/>
                      Invece, <b>l’Energia</b> (in Watt all’ora) corrisponde a quanto consumi in un’ora: come se mettessimo insieme le varie fotografie degli istanti 
                      fino a costruire un film di <b>un’ora di consumi</b>.</p>
                  </span>
                </div>
              </div>
            )}
          </h2>
          <img src={refreshImg} className="hover-image" id="refresh-image" onClick={handlerRefresh} />
          <SwitchButton onValoreBottone={gestisciValoreBottone} img1={flashImg} img2={euroImg} typeInstant={"instant-button-box"}/>
        </div>
      </div>
      <h5 id="scroll-text">Cosa sta succedendo?</h5>
      <VerticalScroll button={valoreBottone} power={powerInstantValue}/>
    </div>
  );
}
