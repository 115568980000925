import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";

//classe utente // JSON

import "../styles/root.css";
import "../styles/app.css";

import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";

import WVZ from "../context/WVZ";
import User from "../models/User";

import UserService from "../services/user.service";
import DeviceService from "../services/devices.service";

export default function Home() {
  const user: User = WVZ.loggedUser();

  const [showing, setShowing] = useState(false);

  const deviceService = new DeviceService();
  const userService = new UserService();

  const setMenu = () => {
    let box: any | undefined | null;
    box = document.querySelector("body");

    let width = box.offsetWidth;

    if (width > 800) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  };

  setTimeout(function () {
    setMenu();
  }, 1);

  window.onresize = () => {
    setMenu();
  };

  return (
    <>
      <MenuDesktop pageValue={-1} menu={showing} />
      <MenuMobile menu={showing} />
    </>
  );
}
