import WVZ from "../context/WVZ";
import Device from "../models/Device";
import Pod from "../models/Pod";
import User from "../models/User";
import devicesService from "./devices.service";
import DevicesService from "./devices.service";

export class UserService {

    _devices : Device[] = []
    
    get getDevices() {
        return this._devices;
    }

    set setDevice(devices : Device[]) {
        this._devices = devices;
    }

    /**
     * Sends to the backend API a getUser request invoking the user/:id endPoint.
     * If the request succeedes the API send back the user who was requested.
     * @param id the userId
     * @returns User if the request succeedes, otherwise null
     */
    public async getUser(id: number): Promise<User | null> {
        try {
            const res = await fetch(WVZ.API_URL + "user/" + id,
                {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                    },
                }
            );
            if(res.ok){
                console.log(res);
                let user : User = await res.json();
                
                return user;
            } else if(res.status === 401) {
                console.log(res)
                console.log("Unauthorized")
                new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In getUser() an exception happened: " + exception)
        }

        return null;
    }


    /**
     * ONLY ADMIN.
     * Sends to the backend API a listUsers request invoking the user endPoint.
     * If the request succeedes the API send back an user array.
     * @returns User array if the request succeedes, otherwise null
     */
    public async listUsers(): Promise<Array<User> | null> {
        try {
            const res = await fetch(WVZ.API_URL + "user",
                {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                    },
                }
            );
            if(res.ok){
                console.log(res);
                let arrayUser : Array<User> = new Array<User>();
                arrayUser = await res.json();
                return arrayUser;
            } else if(res.status === 401) {
                console.log(res)
                console.log("Unauthorized")
                new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In listUsers() an exception happened: " + exception)
        }

        return null;
    }


    /**
     * Sends to the backend API a getCurrentUser request invoking the user/current endPoint.
     * If the request succeedes the API send back an user array.
     * @returns User array if the request succeedes, otherwise null
     */
    public async getCurrenUser(): Promise<User | null> {
        try {
            const res = await fetch(WVZ.API_URL + "user/current",
                {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                    },
                }
            );
            if(res.ok){
                console.log(res);
                let arrayUser : User = await res.json();
                return arrayUser;
            } else if(res.status === 401) {
                console.log(res)
                console.log("Unauthorized")
                new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In getCurrenUser() an exception happened: " + exception)
        }

        return null;
    }

 }


 export default UserService;