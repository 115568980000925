import { useRef, useState, useEffect } from 'react';
import '../../styles/switchButton.css'

export default function SwitchButton({onValoreBottone, img1, img2, img1_grey, img2_grey, flagOther, typeInstant} : any) {

    /* --- Stato che tiene traccia del colore delle immagini da mostrare --> grigio quando non selezionato, bianco altrimenti */
    const [image_left, setImageLeft] = useState<any>();
    const [image_right, setImageRight] = useState<any>();

    const btnRef : any = useRef(null); // Referenza al div del bottone

    // Setto colore immagini bottone
    useEffect(()=>{
        if(typeInstant){
            setImageLeft(img1);
            setImageRight(img2);
        }else{
            setImageLeft(img1);
            setImageRight(img2_grey);
        }
    }, [])

    const leftClick = () => {
        if (btnRef.current) btnRef.current.style.left = '5%';
        if(!typeInstant){
            setImageLeft(img1);
            setImageRight(img2_grey);
        }
        onValoreBottone(0);
    };

    const rightClick = () => {
        if (btnRef.current) btnRef.current.style.left = '50%';
        if(!typeInstant){
            setImageLeft(img1_grey);
            setImageRight(img2);
        }
        onValoreBottone(1);
    };

    return (
        <>
        <div className="form-box">
            <div id={typeInstant} className={flagOther ? "button-box-other" : "button-box"}>
                <div ref={btnRef} id={flagOther ? "btn-other" : "btn"}></div>
                <button type="button" className={flagOther ? "toggle-btn-other" : "toggle-btn"} onClick={leftClick}><img id="img_left" src={image_left}/></button>
                <button type="button" className={flagOther ? "toggle-btn-other" : "toggle-btn"} onClick={rightClick}><img src={image_right}/></button>
            </div>
        </div>
        
        </>
    );
};
