import WVZ from "../context/WVZ";
import Energy from "../models/Energy";
import Power from "../models/Power";


export class MessageService {

    /**
     * Sends to the backend API a getPower request invoking the messages/power endPoint.
     * If the request succeedes the API send back a Power object.
     * @param body is a string like
     * "{
     *       "from": "2023-05-26T00:00:00.000Z",
     *       "to": "2023-05-27T00:00:00.000Z",
     *       "deviceId": "c2g-273468723"
     *  }"
     * where "to" is optional row.
     * @returns Power object if the request succeedes, otherwise null
     */
    public async getPower(body : string) : Promise<Array<Power> | null> {
        try {
            const res = await fetch(WVZ.API_URL + "messages/power",  {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                        'content-type': 'application/json'
                    },
                    body: body
                }
            );
            if(res.ok) {
                let powerObject : Array<Power> = await res.json();
                // console.log("powerObject: ");
                // console.log(powerObject);
                return powerObject;
            } else if(res.status === 400) {
                new Error("Bad Request");
            } else if(res.status === 401) {
                new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In getPower() an exception happened: " + exception)
        }

        return null;
    }

    /**
     * Sends to the backend API a getActiveEnergy request invoking the messages/activeenergy endPoint.
     * If the request succeedes the API send back a Energy object.
     * @param body is a string like
     * "{
     *       "from": "2023-05-26T00:00:00.000Z",
     *       "to": "2023-05-27T06:00:00.000Z",
     *       "deviceId": "c2g-823749738"
     *  }"
     * where "to" is optional row.
     * @returns Energy object if the request succeedes, otherwise null
     */
    public async getActiveEnergy(body : string) : Promise<Array<Energy> | null> {
        try {
            const res = await fetch(WVZ.API_URL + "messages/activeenergy",  {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                        'content-type': 'application/json'
                    },
                    body: body
                }
            );
            if(res.ok) {
                let energyObject : Array<Energy> = await res.json();
                // console.log("energyObject: ejooooo");
                // console.log(energyObject);
                return energyObject;
            } else if(res.status === 400) {
                new Error("Bad Request");
            } else if(res.status === 401) {
                new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In getActiveEnergy() an exception happened: " + exception)
        }

        return null;
    }

 }

 export default MessageService;