import '../../styles/controlValues.css'

import flashImg from '../../images/flash.svg'
import euroImg from '../../images/euro.svg'
import flash_grey from '../../images/flash_grey.svg'
import euro_grey from '../../images/euro_gray.svg'
import wattImg from '../../images/Watt.svg'
import watt_grey from '../../images/Watt_grey.svg'
import kiloWattImg from '../../images/kiloWatt.svg'
import kiloWatt_grey from '../../images/kiloWatt_grey.svg'

import SwitchButton from "../OthersComponents/SwitchButton";

export default function ControlValue({onValoreWatt_Euro, onValoreWatt_Kilo, changeMedia, changeOther, typeOther} : any){

  /**
   * !! IMPORTANTE !!
   * typeOther serve per discriminare a quale grafico devo cambiare i valori e modificare
   * il componente di conseguenza. Ha 3 valori possibili:
   * - 0 -->  Grafico Ora Prima
   * - 1 -->  Grafico Ieri
   * - 2 -->  Grafico Settimana
   * 
   * changeOther è un nome generale per indicare quale grafico (Ora prima, 7 giorni prima, ecc)
   * devo mostrare o meno, comunque la funzione chiamata da changeOther la dichiaro
   * nei vari componenti che la usano (addLinea per l'Ora Prima in GraphLastHour, ad esempio)
   */

    const changeAverage = () =>{
        changeMedia(0);
    }

    const changeOtherGraph = () =>{
        changeOther(0);
    }

    const changeValueWatt_Euro = (num: number) =>{
      onValoreWatt_Euro(num);
    }

    const changeValueWatt_Kilo = (num: number) =>{
      onValoreWatt_Kilo(num);
    }

    let firstPhrase : string = "Media";
    let secondPhrase : string = "Ultima Ora";
    let thirdPhrase : string = "Ora Prima";
    let toHide : string = "";
    let toHideSwitchWattKilo : string = "";
    let flagOther : number = 0;

    if(typeOther===1){ // Grafico Ieri
      secondPhrase = "Ieri"
      thirdPhrase = "7 giorni fa"
      toHideSwitchWattKilo = "none";
      flagOther = 1;
      
    }else if(typeOther === 2){ // Grafico Settimana
      firstPhrase = ""
      toHide = "none";
      secondPhrase = "7 giorni fa"
      thirdPhrase = "14 giorni fa"
      toHideSwitchWattKilo = "none";
      flagOther = 1;
    }

    return(
        <div className="lastHour-settings">
          <h5 id="control-text">Regola i valori</h5>
          <div className="container-value">
            <h3>
              <span>Che dati vedo</span>
            </h3>
            <div className="checkbox-container">
              <label style={{display : toHide}}>
                <input type="checkbox" id="first-button" className="checkbox-value" onClick={changeAverage} />
                {firstPhrase}
              </label>
              <label>
                <input type="checkbox" id="second-button" className="checkbox-value" checked readOnly />
                {secondPhrase}
              </label>
              <label>
                <input type="checkbox" id="third-button" className="checkbox-value" onClick={changeOtherGraph} />
                {thirdPhrase}
              </label>
            </div>
            <h3>
              <span>Come vedo i dati</span>
            </h3>
            <div className="buttons">
              <label className="label-buttons">
                <SwitchButton onValoreBottone={changeValueWatt_Euro} img1={flashImg} img2={euroImg} img1_grey={flash_grey} img2_grey={euro_grey} flagOther={flagOther}/>
              </label>
              <label className="label-buttons" style={{display: toHideSwitchWattKilo}} >
                <SwitchButton onValoreBottone={changeValueWatt_Kilo} img1={wattImg} img2={kiloWattImg} img1_grey={watt_grey} img2_grey={kiloWatt_grey}/>
              </label>
            </div>
          </div>
        </div>
    )
}