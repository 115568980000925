import "bootstrap/dist/css/bootstrap.min.css";

import "./styles/root.css";
import WVZ from "./context/WVZ";

import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Profilo from "./components/Profilo";
import Monitoraggio from "./components/Monitoraggio";
import RecupPass from "./components/RecupPass";
import CER from "./components/CER";
import Settings from "./components/Settings";
import Login from "./components/Login";
import Bilancio from "./components/Bilancio";

function App() {
  if (WVZ.loggedUser()) {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profilo" element={<Profilo />} />
          <Route path="/monitoraggio" element={<Monitoraggio />} />
          <Route path="/cer" element={<CER />} />
          <Route path="/bilancio" element={<Bilancio />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/recupPass" element={<RecupPass />} />
        </Routes>
      </>
    );
  } else {
    return <Login />;
  }
}

export default App;
