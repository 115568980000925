
import '../../styles/verticalScroll.css'

import { useEffect, useState } from 'react';

/* --- Tutte le immagini che verranno usate a seconda dei casi --- */
import { euro_1, euro_2, euro_3, euro_4, euro_5 } from '../../images';
import { flash_1, flash_2, flash_3, flash_4, flash_5 } from '../../images';
import { Asciugacapelli, Asciugatrice, Aspirapolvere, Bimby, Bollitore, Congelatore, Condizionatore, Ferro_Stiro, Forno, Friggitrice, Frigorifero, Frullatore, Frusta, Impastatrice, Lavastoviglie, Lavatrice, Caffe, Microonde, Mini_Pimer, Piastra_Induzione, Piastra, Stufetta, TV, Tostapane, Vaporiera, Ventilatore } from '../../images';


export default function VerticalScroll(button : any) {

  const Kwh_Cost = 0.00028; // DA CAMBIARE valore moltiplicatore 

  /*--- RANGE DI VALORI WATT ED EURO --- */
  const watt_values : any[] = ["2.000W - 3.000W", "1.000W - 2.000W", "500W - 1.000W", "250W - 500W", "50W - 250W", "~0W - 50W"];
  const euro_values = ["0,56€ - 0,84€", "0,28€ - 0,56€", "0,14€ - 0,28€", "0,07€ - 0,14€", "0,014€ - 0,07€", "0,00028€ - 0,014€"]; // calcolato moltiplicando ogni range dei watt per il costo in Kwh
  
  /* --- ELETTRODOMESTICI IN BASE A FASCIA DI CONSUMO --- 
  * (da rivedere ottimizzazione e leggibilità se esiste di migliore per prendere il nome degli elettrodomestici, ma funziona)
  */
  const images_0 : any[][] = [[Friggitrice, "Friggitrice"], [Aspirapolvere, "Aspirapolvere"], [Bollitore, "Bollitore"], [Frullatore, "Frullatore"], [Asciugacapelli, "Asciugacapelli"], [Ferro_Stiro, "Ferro Da Stiro"], [Piastra_Induzione, "Piastra Ad Induzione"], [Stufetta, "Stufetta"]];
  const images_1 : any[][] = [[Caffe, "Macchina del Caffé"], [Condizionatore, "Condizionatore"], [Friggitrice, "Friggitrice"], [Frullatore, "Frullatore"], [Bimby, "Bimby"], [Tostapane, "Tostapane"], [Forno, "Forno"], [Impastatrice, "Impastatrice"], [Mini_Pimer, "Mini Pimer"], [Vaporiera, "Vaporiera"], [Stufetta, "Stufetta"], [Asciugatrice, "Asciugatrice"], [Piastra_Induzione, "Piastra Ad Induzione"], [Bollitore, "Bollitore"]];
  const images_2 : any[][] = [[Microonde, "Microonde"], [Frusta, "Frusta Elettrica"],  [Impastatrice, "Impastatrice"], [Asciugatrice, "Asciugatrice"], [Mini_Pimer, "Mini Pimer"], [Caffe, "Macchina del Caffé"], [Aspirapolvere, "Aspirapolvere"], [Asciugacapelli, "Asciugacapelli"], [Frullatore, "Frullatore"], [Bimby, "Bimby"], [Tostapane, "Tostapane"], [Ferro_Stiro, "Ferro Da Stiro"], [Vaporiera, "Vaporiera"], [Bollitore, "Bollitore"]];
  const images_3 : any[][] = [[Microonde, "Microonde"], [Frullatore, "Frullatore"], [Lavatrice, "Lavatrice"],  [Impastatrice, "Impastatrice"], [Lavastoviglie, "Lavastoviglie"], [Asciugatrice, "Asciugatrice"], [Bollitore, "Bollitore"], [Frusta, "Frusta Elettrica"], [Mini_Pimer, "Mini Pimer"]];
  const images_4 : any[][] = [[Ventilatore, "Ventilatore"], [Lavastoviglie, "Lavastoviglie"], [Bollitore, "Bollitore"], [Lavatrice, "Lavatrice"], [Piastra, "Piastra Per Capelli"], [Frusta, "Frusta Elettrica"], [Congelatore, "Congelatore"], [Mini_Pimer, "Mini Pimer"]];
  const images_5 : any[][] = [[Piastra, "Piastra Per Capelli"], [Ventilatore, "Ventilatore"], [Frigorifero, "Frigorifero"], [Congelatore, "Congelatore"], [TV, "TV"]];

  const images : any[][][] = [images_0, images_1, images_2, images_3, images_4, images_5];
  const flashes : any[] = [flash_5, flash_4, flash_3, flash_2, flash_1, flash_1];
  const euros : any[] = [euro_5, euro_4, euro_3, euro_2, euro_1, euro_1];

  const [scrollValues, setScrollValues] = useState<any[]>([]);

  /* --- Controllo a che range appartiene il valore di Potenza passato al Componente --- */
  useEffect(()=>{
    let powerValue = button.power;
    let range = 0;
    if(powerValue >= 3000 || (powerValue >= 2000 && powerValue < 3000 )){
      range = 0;
    }else if (powerValue >= 1000 && powerValue < 2000){
      range = 1;
    }else if (powerValue >= 500 && powerValue < 1000){
      range = 2;
    }else if (powerValue >= 250 && powerValue < 500){
      range = 3;
    }else if (powerValue >= 50 && powerValue < 250){
      range = 4;
    }else if (powerValue > 0 && powerValue < 50){
      range = 5;
    }else{
      range = 0;
    }
    
    whatIsThat(range);

  }, [button])

  /* --- COSTRUZIONE ARRAY ELEMENTI --- */
  const whatIsThat = (range_index : number) =>{
    let values : any = [];
    for(let i = 0; i < images[range_index].length; i++){
      if(button.power > 0 && button.power != '---' ){
      values.push(
      <div>
        <div className="scroll-elements">
          <div className="element-image">
            <img className="inner-image" src={images[range_index][i][0]}/>
          </div>
          <div className="element-description" >
            <div className="element-title">
              {images[range_index][i][1]}
            </div>
            <div className="element-range">
              {button.button == 0 && <label className="element-range-label">{watt_values[range_index]}</label>}
              {button.button == 1 && <label className="element-range-label">{euro_values[range_index]}</label>}
            </div>
            <div className="element-levels">
              {button.button == 0 && <img src = {flashes[range_index]}/>}
              {button.button == 1 && <img src = {euros[range_index]}/>}
            </div>
          </div>
        </div>
        {i < images[range_index].length - 1 && <hr className="rounder-divider"/>}
      </div>
      )
      }
    }
    setScrollValues(values);
  }



  return (
    
    <div className="vertical-scroll">
      <div className="scroll-content">
        <div className="vertical-scroll">
          {scrollValues.map((item, index) => (
            <div key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
    
  );
}
