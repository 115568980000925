import WVZ from "../context/WVZ";
import Device from "../models/Device";


export class DevicesService {

    /**
     * Sends to the backend API a getUserDevices request invoking the devices/:userId endPoint.
     * If the request succeedes the API send back a Device object.
     * @param userId is a string
     * @returns All the devices connected to a user if the request succeedes, otherwise null
     */
    public async getUserDevices(userId: number) : Promise<Device[] | null> {
        try {
            const res = await fetch(WVZ.API_URL + "devices/" + userId,  {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Authorization' : WVZ.authHeader(),
                    },
                }
            );
            if(res.ok) {
                let arrayDevices : Array<Device> = await res.json();
                for (var i = 0; i < arrayDevices.length; i++) {
                    arrayDevices[i].id = arrayDevices[i].id.replace("C2G_", "c2g-");
                    arrayDevices[i].id = arrayDevices[i].id.replace("C2G-", "c2g-");
                }
                return arrayDevices;
            } else if(res.status === 401) {
               new Error("Unauthorized");
            }
        } catch(exception) {
            console.log("In getUserDevices() an exception happened: " + exception)
        }

        return null;
    }
}

export default DevicesService;