import { useEffect, useState } from "react";
import "../../styles/Charts/graphLastHour.css";

import MessageService from "../../services/messages.service";

import Energy from "../../models/Energy";

import { Bar, Line } from "react-chartjs-2";
import moment from "moment";
import ControlValue from "../OthersComponents/ControlValue";


export default function IstogrammaWeek(selectedDevice: any) {
  const messageService = new MessageService();

  const [labels, setLabels] = useState<Array<String>>([]);
  const [values, setValues] = useState<(number | undefined)[]>([]);
  const [dataChart, setDataChart] = useState<any>();

  const [options, setOptions] = useState<any>({});

  const [twoWeekLabels, setTwoWeekLabels] = useState<(number | undefined)[]>([]);
  const [twoWeekValues, setTwoWeekValues] = useState<(number | undefined)[]>([]);
  const [isCheckedLinea, setCheckedLinea] = useState(false);

  const [valuesOfWeekDay, setvaluesOfWeekDay] = useState<number[][]>([]);
  const [valuesOfTwoWeekDay, setvaluesOfTwoWeekDay] = useState<number[][]>([]);
  const [lablesOfWeekDay, setlabelsOfWeekDay] = useState<string[][]>([]);
  const [lablesOfTwoWeekDay, setlabelsOfTwoWeekDay] = useState<string[][]>([]);

  const [dataChartSelected, setDataChartSelected] = useState<any>();
  const [optionsSelected, setOptionsSelected] = useState<any>({});

  const [isClicked, setClicked] = useState(false);
  const [indexSelected, setIndexSelected] = useState<any>();
  const [datasetIndex, setDatasetIndex] = useState<any>();

  const [valuesSelected, setValuesSelected] = useState<any>();
  const [twoWeekValuesSelected, setTwoWeekValuesSelected] = useState<any>();
  const [labelsSelected, setlabelsSelected] = useState<any>();

  //RICAVO GIORNO PRECEDENTE
  const today = new Date();
  const todayMid = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 2, 0, 0, 0).toISOString();
  const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 2, 0, 0, 0).toISOString();
  const twoWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14, 2, 0, 0, 0).toISOString();

  const Kwh_Cost = 0.00028; // DA CAMBIARE valore moltiplicatore 
  const [suggestedMax, setMax] = useState(1000); // Mi serve per cambiare il valore massimo suggerito del grafico a seconda dell'unità di misura

  // --- Bottoni di switch Euro-Watt e Watt-KiloWatt ---
  const [Euro_Watt_week, setEuro_Watt_W] = useState(0);

  const gestisciWatt_Euro_W = (valore: any) => {
    setEuro_Watt_W(valore);
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------


  // Dati grafico due settimane prima BARRE
  const lineaPrecedente = {
    tension: 0.5,
    backgroundColor: "rgb(153, 204, 51, 0.5)",
    hoverBackgroundColor : "rgb(153, 204, 51, 0.9)",
    data: twoWeekValues,
    fill: true,
    borderColor: "#FF0000",
    borderRadius: 15,
    pointBackgroundColor: "#FF0000",
    pointBorderColor: "#FF0000",
    pointRadius: 3,
  };

  // Dati grafico due settimane prima LINEA
  const lineaPrecedenteSelected = {
    tension: 0.5,
    data: twoWeekValuesSelected,
    fill: true,
    backgroundColor: "rgb(153, 204, 51, 0.1)", // Colore di sfondo delle tooltips
    borderColor: "rgb(153, 204, 51)",
    pointBackgroundColor: "rgb(153, 204, 51)",
    pointBorderColor: "rgb(153, 204, 51)",
    pointHoverBackgroundColor: "#003366",
    pointHoverBorderColor: "#003366",
    pointRadius: 2,
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-------- LINEA SETTIMANA ---------
  //1) CHIAMATA PER I DATI DEL GRAFICO SETTIMANALE
  useEffect(() => {
    if (selectedDevice.device != undefined) {
      //----- LINEA DI QUESTA SETTIMANA -----
      let body = {
        from: oneWeekAgo,
        to: todayMid,
        deviceId: selectedDevice.device,
      };
      try {
        messageService.getActiveEnergy(JSON.stringify(body)).then((IntantPowerObject: Array<Energy> | null) => {
          if (IntantPowerObject) {
            let instoValues = [];
            let max = 0;

            let arrayValues: any = [];
            let valuesSingleDay = [];

            let arrayLabels: any = [];
            let labelsSingleDay = [];

            let days = Array.from(new Set(IntantPowerObject.map((item: any) => item.ts.substring(0, 10))));

            for (var i = 0; i < days.length; i++) {
              let weekValues = IntantPowerObject.filter((item: any) => item.ts.startsWith(days[i]));
              for (var k = 0; k < weekValues.length; k++) {
                valuesSingleDay.push(weekValues[k].InstantPower);
                labelsSingleDay.push(weekValues[k].ts);

                if (weekValues[k].InstantPower > max) {
                  max = weekValues[k].InstantPower;
                }
              }
              arrayValues.push(valuesSingleDay);
              arrayLabels.push(labelsSingleDay);
              valuesSingleDay = [];
              labelsSingleDay = [];
              instoValues.push(max);
              max = 0;
            }

            setvaluesOfWeekDay(arrayValues);
            setlabelsOfWeekDay(arrayLabels);

            setValues(instoValues);
            setLabels(daysOfWeek(days));

            //----- DUE SETTIMANE -----
            body = {
              from: twoWeekAgo,
              to: oneWeekAgo,
              deviceId: selectedDevice.device,
            };

            try {
              messageService.getActiveEnergy(JSON.stringify(body)).then((IntantPowerObject: Array<Energy> | null) => {
                if (IntantPowerObject) {
                  instoValues = [];
                  max = 0;

                  arrayValues = [];
                  valuesSingleDay = [];
      
                  arrayLabels = [];
                  labelsSingleDay = [];

                  days = Array.from(new Set(IntantPowerObject.map((item: any) => item.ts.substring(0, 10))));

                  for (var i = 0; i < days.length; i++) {
                    let weekValues = IntantPowerObject.filter((item: any) => item.ts.startsWith(days[i]));
                    for (var k = 0; k < weekValues.length; k++) {
                      valuesSingleDay.push(weekValues[k].InstantPower);
                      labelsSingleDay.push(weekValues[k].ts);

                      if (weekValues[k].InstantPower > max) {
                        max = weekValues[k].InstantPower;
                      }
                    }
                    arrayValues.push(valuesSingleDay);
                    arrayLabels.push(labelsSingleDay);
                    valuesSingleDay = [];
                    labelsSingleDay = [];
                    instoValues.push(max);
                    max = 0;
                  }

                  console.log("Dati caricati");
                  setvaluesOfTwoWeekDay(arrayValues);
                  setlabelsOfTwoWeekDay(arrayLabels);

                  setTwoWeekValues(instoValues);
                  setTwoWeekLabels(daysOfWeek(days));

                }
              });
            } catch (exception) {
              console.log("Error getIntantPower: " + exception);
            }
          }
        });
      } catch (exception) {
        console.log("Error getIntantPower: " + exception);
      }
    }
  }, [selectedDevice.device]);

  // SETTO GRAFICO ISTOGRAMMA
  useEffect(() => {
    setDataChart({
      labels,
      datasets: [
        {
          tension: 0.5,
          backgroundColor: "rgba(0, 51, 102, 0.3)", // Colore di sfondo delle tooltips
          data: values,
          fill: true,
          borderColor: "#003366",
          borderRadius: 15,
          pointBackgroundColor: "#003366",
          pointBorderColor: "#003366",
          hoverBackgroundColor: "rgba(0, 51, 102, 0.8)",
          pointRadius: 3,
        },
      ],
    });

    setOptions({
      maintainAspectRatio: false,
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: suggestedMax,
          ticks: {
            precision: 0, // Mostra cifre senza decimali
          },
        },
      },
      elements: {
        line: {
          borderWidth: 4, // Spessore del bordo della linea
        },
      },
      plugins: {
        tooltip: {
          displayColors: false, // Nasconde il quadrato dei colori
          titleAlign: "center", // Allineamento del titolo delle tooltips
          bodyAlign: "center", // Allineamento del testo delle tooltips
          titleFont: {
            weight: "bold", // Titolo in grassetto
            align: "center",
          },
          bodyFont: {
            weight: "bold",
            size: 20, // Dimensione del testo
          },
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += context.parsed.y + (Euro_Watt_week ? " €" : " W");
              }
              return label;
            },
          },
        },
        legend: {
          display: false, // Nasconde la legenda
        },
      },
      onClick: (event: MouseEvent, activeElements: any[]) => {
        if (activeElements.length > 0) {
          const index = activeElements[0].index;
          const datasetIndex = activeElements[0].datasetIndex;
          handleBarClick(index, datasetIndex);
        }else{
          setClicked(false);
          setValuesSelected(0);
          setlabelsSelected(0);
        }
      },
    });
  }, [labels, values, valuesOfTwoWeekDay, valuesOfWeekDay]);

  // SETTO GRAFICO LINEA
  useEffect(() => {
    let labels = labelsSelected;
    setDataChartSelected({
      labels,
      datasets: [
        {
          tension: 0.5,
          backgroundColor: "rgba(0, 51, 102, 0.2)", // Colore di sfondo delle tooltips
          data: valuesSelected,
          fill: true,
          borderColor: "#003366",
          pointBackgroundColor: "#003366",
          pointBorderColor: "#003366",
          pointHoverBackgroundColor: "rgb(153, 204, 51)",
          pointHoverBorderColor: "rgb(153, 204, 51)",
          pointRadius: 2
        },
      ],
    });

    setOptionsSelected({
      maintainAspectRatio: false,
      scales: {
        y: {
          suggestedMin: 5,
          suggestedMax: suggestedMax,
          ticks: {
            precision: 0, // Mostra cifre senza decimali
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2, // Spessore del bordo della linea
        },
      },
      plugins: {
        tooltip: {
          displayColors: false, // Nasconde il quadrato dei colori
          titleAlign: "center", // Allineamento del titolo delle tooltips
          bodyAlign: "center", // Allineamento del testo delle tooltips
          titleFont: {
            weight: "bold", // Titolo in grassetto
            align: "center",
          },
          bodyFont: {
            weight: "bold",
            size: 20, // Dimensione del testo
          },
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += context.parsed.y + (Euro_Watt_week ? " €" : " W");
              }
              return label;
            },
          },
        },
        legend: {
          display: false, // Nasconde la legenda
        },
      },
    });
   
  }, [valuesSelected, labelsSelected]);

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // CAMBIO VALORI GRAFICO LINEA AUTOMATICO --> necessario in quanto il grafico a linea DIPENDE dall'istogramma
  useEffect(()=>{
    if(indexSelected >= 0 && isClicked) handleBarClick(indexSelected, datasetIndex);
  }, [valuesOfWeekDay, lablesOfWeekDay, valuesOfTwoWeekDay, lablesOfTwoWeekDay]);

  // CONVERSIONE EURO - WATT
  useEffect(()=>{
    if(selectedDevice.device != undefined ){
      let arrayVal : number[]  = [];
      let arrayValTwoWeek : number[]  = [];

      let arraySingleValue : number[] = [];
      let arraySingleValueTwoWeek : number[] = []
  
      let arrayDay : any = []
      let arrayTwoWeek : any = [];

      values.forEach((value : number | undefined) => arrayVal.push(convertEuroWatt(value!, Euro_Watt_week)));
      twoWeekValues.forEach((value : number | undefined) => arrayValTwoWeek.push(convertEuroWatt(value!, Euro_Watt_week)));

      for(let i = 0; i < valuesOfWeekDay.length; i++){
        for(let k = 0; k < valuesOfWeekDay[i].length; k++){
          arraySingleValue.push(convertEuroWatt(valuesOfWeekDay[i][k], Euro_Watt_week));
          arraySingleValueTwoWeek.push(convertEuroWatt(valuesOfTwoWeekDay[i][k], Euro_Watt_week));
        }
        arrayDay.push(arraySingleValue);
        arrayTwoWeek.push(arraySingleValueTwoWeek);
        arraySingleValue = [];
        arraySingleValueTwoWeek = [];
      }

      setValues(arrayVal);
      setTwoWeekValues(arrayValTwoWeek); 
      setvaluesOfWeekDay(arrayDay);
      setvaluesOfTwoWeekDay(arrayTwoWeek);
    }
  }, [Euro_Watt_week])

  // PERMANENZA GRAFICO 2 SETTIMANE PRIMA ISTOGRAMMA
  /* --- I grafici delle 2 settimane prima RIMANGONO anche dopo aver cliccato sul cambio di unità di misura --- */
  useEffect(()=>{
    if(isCheckedLinea){
      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index != 1)
      }));

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedente],
      }));

    }
  }, [twoWeekValues]);

  // PERMANENZA GRAFICO 2 SETTIMANE PRIMA LINEA
  useEffect(()=>{
    if(isCheckedLinea){
      setDataChartSelected((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== 1),
      }));
      setDataChartSelected((prevData : any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedenteSelected],
      }));
    }
  }, [twoWeekValuesSelected]);

  // AGGIUNTA GRAFICO 2 SETTIMANE PRIMA
  const addTwoWeek = () => {
    setCheckedLinea(!isCheckedLinea);
    if (!isCheckedLinea) {

      setDataChart((prevData : any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedente],
      }));

      setDataChartSelected((prevData : any) => ({
        ...prevData,
        datasets: [...prevData.datasets, lineaPrecedenteSelected],
      }));
      
    } else {
      const datasetToRemoveIndex = 1; // Indice del dataset da rimuovere 

      setDataChart((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== datasetToRemoveIndex),
      }));

      setDataChartSelected((prevData: any) => ({
        ...prevData,
        datasets: prevData.datasets.filter((_: any, index: any) => index !== datasetToRemoveIndex),
      }));
    }
  };


  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // FUNZIONE CONVERSIONE EURO - WATT
  const convertEuroWatt = (powerValue : number, flagEuro_Watt : number) =>{
    let value : number = powerValue;
    if(flagEuro_Watt){
      value = parseFloat((powerValue*Kwh_Cost).toFixed(4));
      setMax(1);
    }else{
      value = Math.floor(powerValue / Kwh_Cost);
      setMax(1000);
    }

    return value;
  }

  const daysOfWeek = (days: any) => {
    const daysOfWeek = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];

    for (var i = 0; i < days.length; i++) {
      const date = new Date(days[i]);
      const dayOfWeekNumber = date.getDay();
      days[i] = daysOfWeek[dayOfWeekNumber];
    }
    return days;
  };

  /* --- Funzione che viene chiamata ogni volta che l'utente clicca sull'istogramma --- */
  const handleBarClick = (index: any, datasetIndex : any) => {

    if(datasetIndex === 1){
      setValuesSelected(valuesOfTwoWeekDay[index]);
    }else{
      setValuesSelected(valuesOfWeekDay[index]);
      setTwoWeekValuesSelected(valuesOfTwoWeekDay[index]);
    }

    let tsLabels : string[] = [];
    for (let i = 0; i < lablesOfWeekDay[index].length; i++){
      tsLabels.push(moment.utc(lablesOfWeekDay[index][i]).format("HH:mm"));
    }
    setlabelsSelected(tsLabels);
    setIndexSelected(index);
    setDatasetIndex(datasetIndex);
    setClicked(true);
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
    <div>
      <div className="left-side-lastWeek">
        <div className="controlValueWeek-div">
          <ControlValue onValoreWatt_Euro={gestisciWatt_Euro_W} changeOther={addTwoWeek} typeOther={2}/>
        </div>
      </div>
    </div>
      <div className="week-charts-div">
        <div id="div-week" className="right-side CER-chart">
          <h5>Nell'ultima settimana</h5>
          <div className="CER-chart">{dataChart && <Bar id="myChart" data={dataChart} options={options} />}</div>
          <br />
          <h5>Controlla il giorno specifico</h5>
          <div id="graph-selected" className="line-graph" style={{visibility : valuesSelected ? "visible" : "hidden" }}>
            {dataChartSelected && <Line id="myChartLine" data={dataChartSelected} options={optionsSelected} />}
          </div>      
        </div>
      </div>
    </>
  );
}
