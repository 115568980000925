import "bootstrap/dist/css/bootstrap.min.css"

import '../styles/app.css';
import '../styles/root.css';
import '../styles/menuMobile.css';

import logoImg from '../images/logo.svg';
import profiloImg from '../images/profilo.svg';
import cerImg from '../images/cer.svg';
import bilancioImg from '../images/bilancio.svg';
import controlloImg from '../images/controllo.svg';
import settingsImg from '../images/impostazioni.svg';
import exitImg from '../images/exit.svg';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

export default function MenuMobile(info: any) {
    
    const valu = info.menu;

    const userAuth = new AuthService();
    const navigate = useNavigate();

    // Quando l'utente premerà l'immagine di logout effettua il logout vero e proprio
    const logout = async (event: any) =>{
      event.preventDefault();
      userAuth.logout();
      navigate("/"); //riporto l'utente alla pagina di login
    }

    return (
        <>
        <Navbar key={0} bg="light" expand={false} className="mb-3" style={{display: valu ? 'none' : 'block' }}>
          <Container fluid>
            <Navbar.Brand href="/home">
                <img src={logoImg} className="logoMob" alt="LogoImg"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${0}`}></Navbar.Toggle>
            <Navbar.Offcanvas id={`offcanvasNavbar-expand-${0}`} aria-labelledby={`offcanvasNavbarLabel-expand-${0}`} placement="end" className="menu-showed">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${0}`}>
                        Menù
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="items-menu-mob">
                        <div className="item-menu-div">
                            <img src={profiloImg} className="PROFimg menu-img"/>
                            <Nav.Item className="item-menu-mob">
                                <Nav.Link href="/profilo" className="profilo nav-link-mob">
                                    <p className="item-name">Profilo</p>
                                </Nav.Link>
                            </Nav.Item>
                        </div>
                        <div className="item-menu-div">
                            <img src={cerImg} className="CERimg menu-img"/>
                            <Nav.Item className="item-menu-mob">
                                <Nav.Link href="/cer" className="cer nav-link-mob">
                                    <p className="item-name">CER</p>
                                </Nav.Link>
                            </Nav.Item>
                        </div>
                        <div className="item-menu-div">
                            <img src={bilancioImg} className="BILimg menu-img"/>
                            <Nav.Item className="item-menu-mob">
                                <Nav.Link href="/bilancio" className="bil nav-link-mob">
                                    <p className="item-name">Bilancio</p>
                                </Nav.Link>
                            </Nav.Item>
                        </div>
                        <div className="item-menu-div">
                            <img src={controlloImg} className="CONTRLimg menu-img"/>
                            <Nav.Item className="item-menu-mob">
                                <Nav.Link href="/monitoraggio" className="controllo nav-link-mob">
                                    <p className="item-name">Controllo</p>
                                </Nav.Link>
                            </Nav.Item>
                        </div>
                    </Nav>
                    <Nav className="extra-items-mob">
                        <Nav.Item className="list-extra-items">
                            <Nav.Link href="/settings" className="extra-item-mob link">
                                <img src={settingsImg} className="extra-sett-img"/>
                            </Nav.Link>
                            <Nav.Link href="/" className="extra-item-mob link">
                                <img src={exitImg} className="extra-sett-img" onClick={logout}/>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
        </>    
    );
}